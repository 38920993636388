import { React, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import PremiumErrorIcon from "./PremiumErrorIcon";
import Timer from "./Timer";
import axios from "axios";

const PremiumProductModal = ({ open, onClose, product, user }) => {
  const [time, setTime] = useState(1);
  const [Loadtime, setLoadTime] = useState(30);
  const [openTimer, setOpenTimer] = useState(false);
  const [amount, setAmount] = useState();
  const [total, setTotal] = useState();
  const [orderIdentifyer, setOrderIdentifyer] = useState();
  useEffect(() => {
    setTime(1);
    setLoadTime(30);
    setOrderIdentifyer(generateString(15));
    // checkSuccess();
  }, []);

  // program to generate random strings

  // declare all characters
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  //console.log(user);

  const buyProduct = async (e) => {
    // // percentage calc
    let profit;
    const percentage = (Number(Loadtime) / 100) * Number(amount);
    const availableBalance = user?.balance;
    profit = availableBalance + percentage;
    setTotal(profit);
    const priceToCharge = amount;
    const order = {
      orderId: product.id,
      orderIdentifyer: orderIdentifyer,
      userId: user.userId,
      approved: false,
      firstName: user.firstName,
      amount: priceToCharge,
      amountGained: percentage,
      newTotal: profit,
      minutesSelected: time,
      percentage: Loadtime,
    };
    const options = {
      // position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
      onClose: (props) => {
        window.location.reload();
      },
    };

    if (!amount || amount < 1) {
      toast.error("Amount needs to be greater the 1.00");
      setOpenTimer(false);
    } else {
      const priceToCharge = amount;
      const whereToCharge = user.balance;
      const updatedUserBalance = whereToCharge - priceToCharge;
      if (whereToCharge < priceToCharge) {
        toast.info("Insuficent balance");
        onClose();
        return;
      } else {
        const res = await axios
          .post(
            `https://api.areviewmarketing.com/products/premium/${product.id}`,
            {
              ...order,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              // addcredit();
              // console.log(res.data);
            }
          })
          .catch(function (error) {
            // console.log(error.response?.data);
          });
      }
      setOpenTimer(true);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
  const handleTimeChange = (e) => {
    setTime(e.target.value);
    if (e.target.value == "1") {
      setLoadTime(30);
    } else if (e.target.value == "3") {
      setLoadTime(60);
    }
  };
  if (!open) return null;
  return (
    <>
      <div className="modal" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button onClick={onClose} className="close-modal">
                X
              </button>
              <div className="centered">
                <PremiumErrorIcon />
              </div>
              {openTimer ? (
                <>
                  <div
                    className="timer-container"
                    style={{ textAlign: "center" }}
                  >
                    Submitted, please don't close the window, <br /> automatic
                    reload starts once profit has been released to your account
                  </div>
                  <Timer
                    total={total}
                    order={orderIdentifyer}
                    time={time}
                    amount={amount}
                    Loadtime={Loadtime}
                    user={user}
                  />
                </>
              ) : (
                <>
                  <div className="form-containerx">
                    <div style={{ textAlign: "center" }}>
                      fill in amount and time required for
                      <br /> this order then confirm for profit
                    </div>
                    <br />
                    <div
                      className="form"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label
                        style={{ color: "white" }}
                        className="form-label"
                        htmlFor="time"
                      >
                        Time
                      </label>
                      <select
                        onChange={handleTimeChange}
                        name="time"
                        id="time"
                        className="timeframe"
                      >
                        <option value="1">1 min</option>
                        <option value="3">3 min</option>
                      </select>
                      <br />
                      <label
                        style={{ color: "white" }}
                        className="form-label"
                        htmlFor="amount"
                      >
                        Amount
                      </label>
                      <input
                        type="number"
                        id="amount"
                        className="amount"
                        onChange={handleAmountChange}
                      />
                    </div>
                  </div>
                  <br />
                  <button onClick={buyProduct} className="buy_btn">
                    Confirm
                  </button>
                </>
              )}

              <br />
              <center>
                <a href={`/buy/${product?.url}`} className="hiw centered">
                  Fund Account
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PremiumProductModal;
