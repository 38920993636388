import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Stars from "./Stars";
import PremiumProductModal from "./PremiumProductModal";
import Modal from "./Modal";
import { toast } from "react-toastify";
import axios from "axios";

const Premiumproducts = () => {
  const [openModal, setOpenModel] = useState(false);
  const [product, setProduct] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [user, setUser] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const handleButtonClick = async () => {
    if (user) {
      if (product.available == "yes") {
        setOpenModel(!openModal);
      } else {
        toast.info("This product is not available Now try again later");
      }
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    const fetchProduct = async () => {
      const res = await axios.get(`https://api.areviewmarketing.com/products/${id}`);
      setProduct(res.data[0]);
    };
    fetchProduct();

    const fetchReviesw = async () => {
      const res = await axios.get(
        `https://api.areviewmarketing.com/products/reviews/${id}`
      );
      setReviews(res.data);
    };
    fetchReviesw();
  }, []);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.areviewmarketing.com/")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.areviewmarketing.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      <PremiumProductModal
        open={openModal}
        user={user}
        product={product}
        onClose={() => setOpenModel(false)}
      />
      <div className="wrapper">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-lg-6">
            <div className="product-thumbnail">
              <img src={product?.image} />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-6">
            <h2 className="title">{product?.title}</h2>
            <div className="hide-small reviews space-x-[1px] mb-3">
              <h2 className="reviews">Customer Reviews</h2>
              <div className="review-container">
                <div className="review-icons">
                  <span>
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                        fill="#FFA800"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                        fill="#FFA800"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                        fill="#FFA800"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                        fill="#FFA800"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                        fill="#FFA800"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    Based on{" "}
                    <span style={{ fontWeight: 700 }}>{reviews.length} </span>
                    reviews
                  </span>
                </div>

                <div className="review-button">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1"
                    className="buy_btn "
                    onClick={handleButtonClick}
                  >
                    Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="review-section">
          <div className="reviews space-x-[1px] mb-3">
            <h2 className="reviews">Customer Reviews</h2>
            <div className="review-container">
              <div className="review-icons">
                <span>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                      fill="#FFA800"
                    ></path>
                  </svg>
                </span>
                <span>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                      fill="#FFA800"
                    ></path>
                  </svg>
                </span>
                <span>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                      fill="#FFA800"
                    ></path>
                  </svg>
                </span>
                <span>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                      fill="#FFA800"
                    ></path>
                  </svg>
                </span>
                <span>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                      fill="#FFA800"
                    ></path>
                  </svg>
                </span>
                <span>
                  Based on{" "}
                  <span style={{ fontWeight: 700 }}>{reviews.length} </span>
                  reviews
                </span>
                <br />
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_1"
                  className="buy_btn "
                  onClick={handleButtonClick}
                >
                  Purchase
                </button>
              </div>
            </div>
          </div>
          <div className="reviews-data">
            {reviews?.map((item, index) => (
              <>
                <div className="review_body">
                  <Stars score={item.score} />
                  <p className="reviewer">Review from {item.firstName}</p>
                  <div className="titlex">{item.title}</div>
                  <div>{item.body}</div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Premiumproducts;
