import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import AdminContent from "../components/AdminContent";
import { toast } from "react-toastify";

const initialState = {
  name: "",
  account: "",
  bank: "",
};
const Addbank = () => {
  const [form, setForm] = useState(initialState);
  const [users, setUsers] = useState([]);
  const { Bank, Name, Account } = form;
  const [user, setUser] = useState("");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.areviewmarketing.com/")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.areviewmarketing.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  // get all users
  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.get("https://api.areviewmarketing.com/users");
      setUsers(res.data);
    };
    fetchUsers();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("https://api.areviewmarketing.com/getbank").then((res) => {
      setForm(res.data);
    });
  }, []);
  const handleAddBank = async (id) => {
    const res = await axios
      .put(`https://api.areviewmarketing.com/banks`, {
        ...form,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Bank Updated");
        }
      })
      .catch(function (error) {
        toast.error("Bank Not Added");
      });
    console.log(user);
  };

  return (
    <div>
      <AdminHeader />
      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 order-2 order-lg-1">
            <h2 style={{ textAlign: "center", color: "white" }}>USERS</h2>

            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="p-10 w-100">
                <h2 style={{ textAlign: "center", color: "white" }}>
                  BANKS FOR PAYMNET
                </h2>
                <div class="card ">
                  <br />
                  <div className="bankform">
                    <input
                      type="text"
                      className="name"
                      name="name"
                      value={Name}
                      placeholder="Bank Name"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="name"
                      name="account"
                      value={Account}
                      placeholder="Bank Account"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="name"
                      name="bank"
                      value={Bank}
                      placeholder="Account Number"
                      onChange={handleChange}
                    />
                    <button onClick={handleAddBank} className="add_bank">
                      Add Bank
                    </button>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminContent user={user} />
    </div>
  );
};

export default Addbank;
