import React from "react";

const LocationIcon = () => {
  return (
    <svg
      height="20"
      width="20"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.824 51.824"
    >
      <g>
        <path
          style={{ fill: "#fff" }}
          d="M45.501,18.902C45.116,8.083,36.031-0.374,25.213,0.013C14.395,0.399,5.938,9.482,6.324,20.301
		c0.062,1.745,0.373,3.42,0.859,5.015l-0.022,0.001c0,0,7.398,26.845,18.405,26.504c12.207-0.377,19.017-26.084,19.395-27.672
		c0.037-0.109,0.063-0.184,0.063-0.184h-0.022C45.374,22.339,45.564,20.647,45.501,18.902z M26.435,34.231
		c-8.079,0.289-14.863-6.027-15.151-14.107c-0.289-8.08,6.027-14.863,14.107-15.152c8.08-0.289,14.865,6.027,15.153,14.107
		C40.832,27.158,34.514,33.942,26.435,34.231z"
        />
      </g>
    </svg>
  );
};

export default LocationIcon;
