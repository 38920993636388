import React, { useEffect, useState, useRef } from "react";

import Default from "../assets/images/default.png";
import Waiting from "../assets/images/waiting.png";
import Sent from "../assets/images/sent.png";
import AmazonHeader from "../components/AmazonHeader";
import AmazonFooter from "../components/AmazonFooter";

const initialState = {
  email: "",
  username: "",
  subject: "",
  message: "",
};
const Contact = ({ metaData }) => {
  const [data, setData] = useState();
  const [form, setForm] = useState(initialState);
  const [active, setActive] = useState(null);
  const [passed, setPassed] = useState(false);
  const [openModal, setOpenModel] = useState(false);

  //  prod
  const id = "Cw1TrtdA382NCnAzNcIu";

  // dev
  // const id = "vpmEfY5KkO2YzMEgTSXB";

  const { email, message, subject, username } = form;

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  };
  // push to top page after loading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AmazonHeader active={active} />
      <div className="container-fluid pt-90">
        <div className="wrapper">
          <div className="row">
            <div className="col-md-6 "></div>
            <div className="col-md-6 ">
              <div className="form-container">
                <div className="form-content">
                  <form ref={form} onSubmit={sendEmail} name="contact">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="form">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email address"
                        onChange={handleChange}
                        value={email}
                      />
                      <p
                        class="errorEmail"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          paddingLeft: "12px",
                        }}
                      ></p>
                    </div>
                    <div className="form">
                      <label htmlFor="email">Full Name</label>
                      <input
                        type="text"
                        name="username"
                        placeholder="Enter Name"
                        value={username}
                        onChange={handleChange}
                      />
                      <p
                        class="errorName"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          paddingLeft: "12px",
                        }}
                      ></p>
                    </div>
                    <div className="form">
                      <label htmlFor="Subject">Subject</label>
                      <input
                        type="text"
                        name="subject"
                        placeholder="Enter a subject here"
                        value={subject}
                        onChange={handleChange}
                      />
                      <p
                        class="errorSubject"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          paddingLeft: "12px",
                        }}
                      ></p>
                    </div>
                    <div className="form">
                      <label htmlFor="message">Message</label>
                      <textarea
                        style={{ resize: "none" }}
                        type="text"
                        name="message"
                        placeholder="Write here.."
                        value={message}
                        onChange={handleChange}
                      ></textarea>
                      <p
                        class="errorMessage"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          paddingLeft: "12px",
                        }}
                      ></p>
                    </div>
                    <div className="form">
                      <p
                        class="errorRecaptcha"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          paddingLeft: "12px",
                        }}
                      ></p>
                    </div>

                    <button
                      style={{
                        float: "right",
                        background: "transparent",
                        border: "0",
                      }}
                      type="submit"
                    >
                      <img className="default" src={Default} alt="" />
                      <img
                        style={{ display: "none" }}
                        className="waiting"
                        src={Waiting}
                        alt=""
                      />
                      <img
                        style={{ display: "none" }}
                        className="sent"
                        src={Sent}
                        alt=""
                      />
                    </button>
                    <br />
                    <br />
                    <br />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AmazonFooter />
    </>
  );
};

export default Contact;
