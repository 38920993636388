import { React, useState } from "react";
import AmazonFooter from "../components/AmazonFooter";
import AmazonHeader from "../components/AmazonHeader";
import { toast } from "react-toastify";

const WithdrawSuccess = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const handleWithdraw = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      reload();
      toast.success("WITHDRAW REQUEST SENT");
      document.getElementById("msg").innerText = "PROCESSING";
    }, "3000");
  };

  const reload = () => {
    setTimeout(() => {
      setLoading(false);
      window.location.href = "/";
    }, "3000");
  };
  return (
    <>
      <AmazonHeader user={user} />
      <div className="page-centered">
        <h2 className="payment">Continue to withdraw</h2>
        <p className="desc">
          Kindly click the withdraw button to withdraw to your connected bank
        </p>
        <br />
        <div className="form">
          <div className="bank">
            {/* <p className="balance">
              Current Balance: <span>{user?.balance}</span>
            </p> */}
            <button onClick={handleWithdraw} className="submit_btn">
              {loading ? (
                <div class="nedloader"></div>
              ) : (
                <span id="msg">WITHDRAW</span>
              )}
            </button>
          </div>
        </div>
        <br />
      </div>
      <AmazonFooter />
    </>
  );
};

export default WithdrawSuccess;
