import React, { useEffect, useState } from "react";
import AmazonHeader from "../components/AmazonHeader";
import NotificationSlider from "../components/NotificationSlider";
import AmazonFooter from "../components/AmazonFooter";
import PremiumProductDetails from "../components/PremiumProductDetails";

const ProductPremium = ({ user }) => {
  return (
    <div>
      <AmazonHeader />
      <NotificationSlider />
      <br />
      <br />
      <PremiumProductDetails />
      <AmazonFooter />
    </div>
  );
};

export default ProductPremium;
