import React from "react";

const NotAuthorized = () => {
  return (
    <div className="naut">
      Not Authorized <br />
      Only Accessible by Admins
      <br />
      <a href="/login">LogIn to continue</a>
    </div>
  );
};

export default NotAuthorized;
