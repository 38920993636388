import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AmazonFooter from "../components/AmazonFooter";
import NotificationSlider from "../components/NotificationSlider";
import ProductDetails from "../components/ProductDetails";
import AmazonHeader from "../components/AmazonHeader";
const Product = ({ user }) => {
  return (
    <>
      <AmazonHeader />
      <NotificationSlider />
      <br />
      <br />
      <ProductDetails />
      <AmazonFooter />
    </>
  );
};

export default Product;
