import "../components/AmazonHeader.css";
import React, { useEffect, useState, useContext } from "react";
import LocationIcon from "./LocationIcon";
import LogoutIcon from "./LogoutIcon";
import MenuBar from "./MenuBar";
import SearchIcon from "./SearchIcon";
import UserIcon from "./UserIcon";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AmazonHeader = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  let menu;
  const [user, setUser] = useState("");

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.areviewmarketing.com/")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.areviewmarketing.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);
  const handleLogout = (e) => {
    navigate("/login");
  };

  const handleSearchM = (e) => {
    e.preventDefault();
    const search = document.getElementById("search").value;
    window.location.href = `/search/${search}`;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const search = document.getElementById("searchd").value;
    window.location.href = `/search/${search}`;
  };

  // const search = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (showMenu) {
    // document.getElementbyID("navbr").style.display = "none";
    menu = (
      <div className="container">
        <div className="navigation-menu ">
          <br />
          <br />
          <ul>
            {user ? (
              <div className="userbal">
                <UserIcon />
                <div className="user-info">
                  <div className="user">
                    {user.firstName}, {user.lastName}
                  </div>
                  <div className="user-balance">
                    <span style={{ color: "black" }}>Bal:</span> ${user.balance}
                  </div>
                </div>
              </div>
            ) : (
              <li className="nav-item">
                <a href="/login" className="nav-link">
                  SIGN IN
                </a>
              </li>
            )}

            <li className="nav-item">
              <a href="/buy/37454749" className="nav-link">
                DEPOSIT
              </a>
            </li>
            <li className="nav-item">
              <a href="/withdraw" className="nav-link">
                WITHDRAW
              </a>
            </li>

            <li className="nav-item">
              <a href="/about" className="nav-link">
                ABOUT
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                CONTACT
              </a>
            </li>
            {user ? (
              <li className="nav-item">
                <button
                  style={{
                    border: "0",
                    background: "0",
                    color: "white",
                    fontSize: "14px",
                  }}
                  onClick={handleLogout}
                >
                  LOGOUT
                </button>
              </li>
            ) : (
              ""
            )}
          </ul>
          <hr
            style={{
              borderColor: "#404253",
              width: "300px",
              margin: "0 auto",
              border: "solid 1px",
            }}
          />
          <br />
        </div>
      </div>
    );
  }
  return (
    <>
      {menu}

      <header>
        <nav class="amazon_navbar">
          <div class="nav-logo">
            <a href="/">
              <img
                src="https://cdn.shopify.com/s/files/1/0349/3298/1804/files/logo.png"
                alt=""
              />
            </a>
          </div>
          <div class="address">
            <a href="#" class="deliver">
              <LocationIcon />
              Deliver
            </a>
            <div class="map-icon">
              <span class="material-symbols-outlined">To your loctaion</span>
              <a href="#" class="location"></a>
            </div>
          </div>
          <div class="nav-search">
            <select class="select-search">
              <option>All</option>
              <option>All Categories</option>
              <option>Amazon Devices</option>
            </select>
            <input
              type="text"
              id="searchd"
              placeholder="Search Amazon"
              class="search-input"
            />
            <div class="search-icon" onClick={handleSearch}>
              <span class="material-symbols-outlined">
                <SearchIcon />
              </span>
            </div>
          </div>
          <div class="sign-in">
            <a href="#">
              <p>Hello, </p>
              {user ? <span>{user.firstName}</span> : <span>Guest</span>}
            </a>
          </div>
          <div class="returns">
            <p>Balance</p>
            {user ? <span>{user?.balance}</span> : <span>0.00</span>}
          </div>
          <div class="cart">
            <button
              onClick={handleLogout}
              style={{ background: "0", border: "0" }}
            >
              <LogoutIcon />
            </button>
          </div>
        </nav>
        <div class="nav-search hide-large">
          <select class="select-search">
            <option>All</option>
            <option>All Categories</option>
            <option>Amazon Devices</option>
          </select>
          <input
            type="text"
            id="search"
            placeholder="Search Amazon"
            class="search-input"
          />
          <div class="search-icon" onClick={handleSearchM}>
            <span class="material-symbols-outlined">
              <SearchIcon />
            </span>
          </div>
        </div>
        <div class="banner">
          <div class="banner-content">
            <div class="panel">
              <span class="material-symbols-outlined">
                <button
                  style={{ background: "0", border: "0" }}
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <MenuBar />
                </button>
              </span>
            </div>

            <ul class="links">
              <li>
                <a href="/buy/37454749">Fund Account</a>
              </li>

              <li>
                <a href="/withdraw">Withdraw</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
            <div class="deals">
              <a href="/terms">Terms & Conditions</a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default AmazonHeader;
