import Home from "./pages/Home";
import AdminHome from "./pages/AdminHome";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Auth from "./pages/Auth";
import { Routes, Route, useNavigate } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import "./media-query.css";
import { useEffect, useState } from "react";

import Protected from "./components/protected";
import Signup from "./pages/Signup";
import Product from "./pages/Product";
import SignupSuccess from "./pages/SignupSuccess";
import Amazon from "./pages/Amazon";
import ProductPremium from "./pages/ProductPremium";
import PaymentPage from "./pages/PaymentPage";
import Logout from "./pages/Logout";
import AdminAccess from "./components/AdminAccess";
import Create from "./pages/Create";
import Search from "./pages/Search";
import EditUser from "./pages/EditUser";
import UserSearch from "./pages/UserSearch";
import Withdraw from "./pages/Withdraw";
import Products from "./pages/Products";
import Editproducts from "./pages/Editproducts";
import AdminPremiumOrders from "./pages/AdminPremiumOrders";
import Citybank from "./pages/Citybank";
import AdminBanks from "./pages/AdminBanks";
import NedBank from "./pages/NedBank";
import Absa from "./pages/Absa";
import Standard from "./pages/Standard";
import Tyme from "./pages/Tyme";
import Fnb from "./pages/Fnb";
import OtherBanks from "./pages/OtherBanks";
import WithdrawSuccess from "./pages/Withdrawsuccess";
import NotAuthorized from "./pages/NotAuthorized";
import Addbank from "./pages/Addbank";

function App() {
  // push to top page after loading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // GET ALL POSTS FROM DB
  // const [loading, setLoading] = useState(false);
  const [ReviewProducts, setReviewProducts] = useState([]);

  // get all products

  return (
    <div className="App">
      <ToastContainer position="top-center" />
      <Routes>
        <Route path="/" element={<Amazon products={ReviewProducts} />} />
        <Route path="/amazon" element={<Amazon products={ReviewProducts} />} />
        <Route path="/not-auth" element={<NotAuthorized />} />
        <Route path="/withdraw/city-bank" element={<Citybank />} />
        <Route path="/withdraw/absa" element={<Absa />} />
        <Route path="/withdraw/ned-bank" element={<NedBank />} />
        <Route path="/withdraw/standard-bank" element={<Standard />} />
        <Route path="/withdraw/tyme-bank" element={<Tyme />} />
        <Route path="/withdraw/fnb-bank" element={<Fnb />} />
        <Route path="/login/success" element={<SignupSuccess />} />
        <Route path="/withdraw/others" element={<OtherBanks />} />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/admin/search/users/:id"
          element={
            <AdminAccess>
              <UserSearch />
            </AdminAccess>
          }
        />
        <Route path="/withdraw/success" element={<WithdrawSuccess />} />
        <Route path="/admin/edit-user/:id" element={<EditUser />} />
        <Route path="/admin/edit-product/:id" element={<Editproducts />} />
        <Route path="/admin/banks" element={<AdminBanks />} />

        <Route path="/logout" element={<Logout />} />

        <Route path="/login" element={<Auth />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/about" element={<About />} />

        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/adminstrator-access/create-user" element={<Signup />} />
        <Route
          path="/amazon/review/normal/:id"
          element={
            <Protected>
              <Product />
            </Protected>
          }
        />
        <Route
          path="/amazon/review/premium/:id"
          element={
            <Protected>
              <ProductPremium />
            </Protected>
          }
        />
        <Route path="/how-it-works" element={<ProductPremium />} />
        <Route path="/buy/:id" element={<PaymentPage />} />
        <Route path="/search/:terms" element={<Search />} />
        <Route path="admin/products" element={<Products />} />
        <Route
          path="/admin"
          element={
            <AdminAccess>
              <AdminHome />
            </AdminAccess>
          }
        />
        <Route
          path="/admin/update-bank"
          element={
            <AdminAccess>
              <Addbank />
            </AdminAccess>
          }
        />
        <Route
          path="/admin/product/add"
          element={
            <AdminAccess>
              <Create />
            </AdminAccess>
          }
        />
        <Route
          path="/admin/orders"
          element={
            <AdminAccess>
              <AdminPremiumOrders />
            </AdminAccess>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
