import { React, useEffect, useState } from "react";
import AmazonHeader from "../components/AmazonHeader";
import AmazonFooter from "../components/AmazonFooter";

import axios from "axios";

const PaymentPage = ({ user, logout }) => {
  const id = "yUxrNnGwYxbbNkYQ9DL9";
  const [data, setData] = useState();

  useEffect(() => {
    fetchBank();
  }, [id]);

  const fetchBank = async () => {
    const res = await axios.get("https://api.areviewmarketing.com/getbank");
    setData(res.data[0]);
  };
  console.log(data);
  return (
    <div>
      <AmazonHeader user={user} logout={logout} />
      <div className="page-centered">
        <h2 className="payment">Make a Deposit</h2>
        <br />
        <table>
          <tr>
            <td style={{ textAlign: "center" }}>BANK NAME</td>
            <td style={{ textAlign: "center" }}>ACCOUNT NO.</td>
            <td style={{ textAlign: "center" }}>ACCOUNT NAME</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>{data?.Bank}</td>
            <td style={{ textAlign: "center" }}>{data?.Account}</td>
            <td style={{ textAlign: "center" }}>{data?.Name}</td>
          </tr>
        </table>
        <br />
        <p style={{ color: "white", textAlign: "center" }}>
          Your Account balance automatically updates in less than 3 mins after
          making payment
        </p>
      </div>
      <AmazonFooter />
    </div>
  );
};

export default PaymentPage;
