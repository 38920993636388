import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AmazonHeader from "../components/AmazonHeader";
import AmazonFooter from "../components/AmazonFooter";
import axios from "axios";

const Search = ({ user }) => {
  const [search, setSearch] = useState([]);
  const id = useParams();

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.get(
        `https://api.areviewmarketing.com/products/search/${id.terms}`
      );
      setSearch(res.data);
    };
    fetchUsers();
  }, []);

  console.log(search);
  if (search[0]?.code != id.terms) {
    return (
      <>
        <AmazonHeader user={user} />
        <center>
          <h2>Not Found</h2>
          <p>Please check your search code..</p>
        </center>

        <AmazonFooter />
      </>
    );
  } else {
    return (
      <>
        <AmazonHeader user={user} />
        <br />
        <div className="wrapper">
          <div className="product-row">
            {search[0]?.premium == "yes" ? (
              <div key={search[0]?.id} className="product-container">
                <a href={`/amazon/review/premium/${search[0]?.id}`}>
                  <div className="prem">Premium</div>
                  <div
                    className="product-media"
                    style={{ backgroundImage: `url(${search[0]?.image})` }}
                  ></div>
                </a>
                <div className="product-details">
                  <div class="reviews space-x-[1px] mb-3">
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                  </div>

                  <div className="product-title">{search?.title}</div>
                </div>
                <br />
              </div>
            ) : (
              <div key={search[0]?.id} className="product-container">
                <a href={`/amazon/review/normal/${search[0]?.id}`}>
                  <div
                    className="product-media"
                    style={{ backgroundImage: `url(${search[0]?.image})` }}
                  ></div>
                </a>
                <div className="product-details">
                  <div class="reviews space-x-[1px] mb-3">
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#FFA800"
                        ></path>
                      </svg>
                    </span>
                  </div>

                  <div className="product-title">{search[0]?.title}</div>
                </div>
                <br />
              </div>
            )}
          </div>
        </div>
        <AmazonFooter />
      </>
    );
  }
};

export default Search;
