import { React, useState, useEffect } from "react";
import AdminHeader from "../components/AdminHeader";
import AdminContent from "../components/AdminContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const UserSearch = () => {
  const [search, setSearch] = useState();
  const [user, setUser] = useState();
  const id = useParams();
  const navigate = useNavigate();
  const searchUser = async () => {
    const term = document.getElementById("searchItem").value;
    navigate(`/admin/search/users/${term}`);
  };
  const clearSearch = () => {
    navigate(`/admin`);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios
        .get(`https://api.areviewmarketing.com/users/search/${id.id}`)
        .catch(function (error) {
          console.log(error);
        });
      setSearch(res.data);
    };
    fetchUsers();
  }, [id.id]);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.areviewmarketing.com/")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.areviewmarketing.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      <AdminHeader />
      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <center>
          <h2>USERS</h2>
        </center>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="p-10 w-100">
                <div className="search_container">
                  <input
                    type="text"
                    id="searchItem"
                    className="search_user"
                    placeholder={id.id}
                  />

                  <button onClick={clearSearch} className="reset">
                    Clear
                  </button>
                </div>
                <br />

                {search?.length > 0 ? (
                  <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                    <thead className="hide-small">
                      <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>Thumbnail</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>Reward Fee</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="hide-small">
                          {search[0]?.FeatureImage ? (
                            <img
                              className="thumbnail-small"
                              src={search[0].FeatureImage}
                              alt={search[0].firstName}
                            />
                          ) : (
                            <img
                              className="thumbnail-small"
                              src="httpss://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
                            />
                          )}
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search[0]?.firstName}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search[0]?.lastName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search[0]?.email}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search[0]?.rewardFee}</h6>
                          </div>
                        </td>

                        <td>
                          <div className="row">
                            <Link
                              style={{ flex: "1" }}
                              to={`/admin/edit-user/${search[0]?.userId}`}
                            >
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="https://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </Link>
                          </div>
                        </td>
                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    <div className="card card-custom">
                      <div className="card-body card-scroll ">
                        <center>
                          <h2 className="not-found">user not found</h2>
                          <p>Make sure you added the correct name</p>
                        </center>
                      </div>

                      <br />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminContent user={user} />
    </>
  );
};

export default UserSearch;
