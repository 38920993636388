import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const AdminAccess = ({ children }) => {
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get("https://api.areviewmarketing.com/")
      .then((res) => {
        if (res.data.valid) {
          axios
            .get(`https://api.areviewmarketing.com/users/${res.data.email}`)
            .then((res) => {
              if (res.data) {
                setUser(res.data[0]);
              }
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  if (user?.isAdmin !== "true") {
    // console.log(user?.isAdmin);
    return (
      <>
        <div className="naut">
          Not Authorized <br />
          Only Accessible by Admins
          <br />
          <a href="/login">LogIn to continue</a>
        </div>
      </>
    );
  } else {
    // console.log(user?.isAdmin);
    return children;
  }
};

export default AdminAccess;
