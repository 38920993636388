import React from "react";
import AmazonHeader from "../components/AmazonHeader";
import AmazonFooter from "../components/AmazonFooter";

const About = ({ metaData }) => {
  return (
    <>
      <AmazonHeader />
      <div className="centered">
        <h2 className="title">About Amazon Review</h2>
        <div className="text-container">
          At Amazon Review Platform, our mission is to enhance the online
          shopping experience for customers by creating positive and informative
          reviews of products. We understand that when it comes to making
          purchasing decisions, reliable and insightful reviews play a crucial
          role. That's why we're here to provide a platform where customers can
          access trustworthy and well-considered feedback on a wide range of
          products available on Amazon.
        </div>
      </div>
      <AmazonFooter />
    </>
  );
};

export default About;
